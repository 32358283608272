<template>
  <van-radio class="azgo-radio--button" v-bind="attrs" :disabled="disabled">
    <slot></slot>
    <template #icon="props">
      <div
        :style="props.checked && 'border-color: var(--tc-color-primary); color: var(--tc-color-primary); background-color: var(--el-color-primary-light-9)'"
        b="1 solid #00000014" text="sm #121212" px="3" h="9" font="300" flex="~ items-center justify-center" rounded="2"
      >
        <span overflow="hidden" whitespace="nowrap" text-ellipsis>
          {{ label }}
        </span>
      </div>
    </template>
  </van-radio>
</template>

<script setup lang="ts">
defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
})

const attrs: Record<string, unknown> = useAttrs()
</script>

<style lang="scss">
.azgo-radio--button {
  --van-padding-sm: 0;
  .van-radio__icon {
    height: fit-content;
    max-width: 100%;
  }
}
</style>
